import React from "react"
import { Container, Box } from "@chakra-ui/react"
import Title from "@app/components/Sections/Title/Title"
import type { PageProps } from "@root/types/global"
import type { Props } from "@app/pages/size-guide"
import { useContent } from "@app/hooks/useContent"
import SizeGuideAccordions from "./SizeGuideAccordions"

const StoreList: React.FC<PageProps<Props>> = ({ page }) => {
  const content = useContent(page)

  return (
    <>
      <Title title={page?.title} />
      <Container maxW="container.md" pb={16} px={[6, 4]}>
        <Box mb={8}>{content}</Box>
        <SizeGuideAccordions />
      </Container>
    </>
  )
}
export default StoreList
