import React from "react"
import { graphql, PageProps } from "gatsby"
import Page from "@app/components/SizeGuide/SizeGuideList"

export type Props = PageProps<GatsbyTypes.PageSizeGuideQuery, GatsbyTypes.PageSizeGuideQueryVariables>

export const query = graphql`
  query PageSizeGuide {
    page: sanityPageSizeGuide {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
